import {
    makeStyles,
    tokens,
    Card,
    Divider,
    Button,
    ProgressBar,
    mergeClasses
} from '@fluentui/react-components';

import { NavLink, useNavigate } from 'react-router-dom';

import {
    Add20Filled,
    DataHistogram24Regular,
    PersonSupport24Filled,
    PersonSupport24Regular,
    Settings24Regular,
    StoreMicrosoft24Regular,
} from '@fluentui/react-icons'


import { fonts } from '../../styles';
import { ENGAGE_USER_ROLES, APPLICATION_ROLES } from '../../app/constants';
import { EngageAppContext } from '../../app/contexts/EngageAppContext';
import { IdentityDataContext } from '../../app/contexts/IdentityDataContext';
import { AccountsDataContext } from '../../app/contexts/AccountsDataContext';
import { useContext, useState, useEffect } from 'react';
import AccountDropdown from '../AccountDropdown';
import SupportPopover from './SupportPopover';
import NotificationIcon from './NotificationIcon';
import { useMediaQuery } from 'react-responsive';
import { ContactsDataContext } from '../../app/contexts/ContactsDataContext';
import { useCardStyles } from '../../styles';

const useStyles = makeStyles({
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: tokens.spacingHorizontalM,
    },
    headerRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: tokens.spacingHorizontalM,
    },
    linkClasses: {

        ...fonts.title,
        color: tokens.colorNeutralForeground1,

        textDecorationLine: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        maxHeight: '24px',
        height: '24px',
    },
    linkClassesActive: {
        color: tokens.colorBrandForegroundInverted,
    },
    createCasesButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

        ...fonts.tableHeader
    },
    plusIcon: {
        marginRight: tokens.spacingHorizontalXS,
    },
    supportIcon: {
        maxHeight: '24px',
        maxWidth: '24px',
    },
    mobileHeaderContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

    },
    mobileAccountDropdown: {
        marginTop: tokens.spacingVerticalM,
    },
    mobileHeaderTopRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    mobileHeaderNavigation: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS
    },
    mobileHeaderLinks: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalS
    },
    mobileMoreNav: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalS
    },
    headerContainer: {
        position: 'relative'
    },
    progressBar: {
        position: 'absolute',
        bottom: '-1px',
        left: 0
    }
});

const linkOptions = [
    {
        icon: <PersonSupport24Regular />,
        path: '/',
        text: 'Home',
        margin: 'Medium',
        roles: []
    },
    {
        icon: <NotificationIcon />,
        path: '/notifications',
        text: 'Notifications',
        roles: []
    },
    {
        icon: <StoreMicrosoft24Regular />,
        path: '/subscriptions',
        text: 'Subscriptions',
        margin: 'Medium',
        roles: [
            ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
            ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
            ENGAGE_USER_ROLES.GLOBAL_READ,
            ENGAGE_USER_ROLES.ADMINISTRATOR
        ]
    },
    {
        icon: <DataHistogram24Regular />,
        path: '/reports',
        text: 'Reports',
        margin: 'Medium',
        roles: [
            ENGAGE_USER_ROLES.SERVICE_MANAGER, 
            ENGAGE_USER_ROLES.ADMINISTRATOR,
            ENGAGE_USER_ROLES.GLOBAL_READ
        ]
    }
]


function Header() {
    const classes = useStyles();
    const cardClasses = useCardStyles();
    const navigate = useNavigate();

    const [links, setLinks] = useState<any[]>(linkOptions);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isLargerThanMobile = useMediaQuery({ query: '(min-width: 1000px)' });
    const { isLoading, adminSiteSettings, loadingArray } = useContext(EngageAppContext);
    const { accounts } = useContext(AccountsDataContext);
    const { contact } = useContext(ContactsDataContext);


    const selectedAccount = accounts.filter(a => a.selected)[0];

    const hasRequiredRole = (requiredRoles: any[]) => {

        if (!contact) {
            return false;
        }

        let result = false;
        requiredRoles.forEach(role => {
            if (contact.userRoles.some(x => x == role)) {
                result = true;
                return;
            }
            
        });

        return result;
    };

    useEffect(() => {
        if (!contact)
            return;

        if (!adminSiteSettings.isLicensingModuleEnabled || !isLargerThanMobile) {
            setLinks(linkOptions.filter((x: any) => x.text != "Subscriptions"))
        }
        else {
            setLinks(linkOptions);
        }

    }, [adminSiteSettings, contact, isLargerThanMobile]);

    const handleCreateTicketClick = () => {
        navigate('/createTicket');
    }

    const DefaultHeader = () => {
        return (<>
            <div className={classes.headerLeft}>
                <Button icon={<Add20Filled />} appearance='primary' onClick={handleCreateTicketClick}>Ticket</Button>
                {
                    accounts.length > 1 && selectedAccount &&
                    <div>
                        <AccountDropdown accounts={accounts} selectedAccount={selectedAccount} />
                    </div>
                }
            </div>
            <div className={classes.headerRight}>
                {
                    links.map(link => {

                        // Show links with no role, or show links that match the users first role
                        if (link.roles.length === 0 || hasRequiredRole(link.roles)) {

                            return (
                                <div key={link.path}>

                                    <NavLink
                                        to={link.path}
                                        className={({ isActive, isPending }) => isActive ? mergeClasses(classes.linkClasses, classes.linkClassesActive) : isPending ? "pending" : classes.linkClasses}
                                    >
                                        {link.icon}
                                    </NavLink>
                                </div>
                            )
                        }
                        else
                            return null;
                    })
                }
                <div>
                    <Divider vertical />
                </div>
                <div className={classes.supportIcon}>
                    <SupportPopover />
                </div>
                <div>
                    {hasRequiredRole([
                        ENGAGE_USER_ROLES.SERVICE_MANAGER,
                        ENGAGE_USER_ROLES.ADMINISTRATOR,
                        ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
                        ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
                        ENGAGE_USER_ROLES.GLOBAL_READ
                    ]) &&
                        <NavLink
                            to={'/settings'}
                            className={({ isActive, isPending }) => isActive ? mergeClasses(classes.linkClasses, classes.linkClassesActive) : isPending ? "pending" : classes.linkClasses}>
                            <Settings24Regular />
                        </NavLink>
                    }
                </div>
                {
                    /* Removing this for now, possibly will be added back in standalone version
                        <ContactPopover />
                    */
                }
            </div>
        </>);
    }

    const MobileHeader = () => {
        return (
            <div className={classes.mobileHeaderContent}>
                <div className={classes.mobileHeaderTopRow}>
                    <Button icon={<Add20Filled />} appearance='primary' onClick={handleCreateTicketClick}>Ticket</Button>
                    <div className={classes.mobileHeaderNavigation}>
                        <div className={classes.mobileHeaderLinks}>
                            {
                                links.map(link => {

                                    // Show links with no role, or show links that match the users first role
                                    if (link.roles.length === 0 || hasRequiredRole([ENGAGE_USER_ROLES.SERVICE_MANAGER])) {

                                        return (
                                            <div key={link.path}>

                                                <NavLink
                                                    to={link.path}
                                                    className={({ isActive, isPending }) => isActive ? mergeClasses(classes.linkClasses, classes.linkClassesActive) : isPending ? "pending" : classes.linkClasses}
                                                >
                                                    {link.icon}
                                                </NavLink>
                                            </div>
                                        )
                                    }
                                    else
                                        return null;
                                })
                            }
                        </div>
                        <div className={classes.mobileMoreNav}>
                            <Divider vertical />
                            <div className={classes.supportIcon}>
                                <SupportPopover />
                            </div>
                            <div>
                                <NavLink
                                    to={'/settings'}
                                    className={({ isActive, isPending }) => isActive ? mergeClasses(classes.linkClasses, classes.linkClassesActive) : isPending ? "pending" : classes.linkClasses}>
                                    <Settings24Regular />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    accounts.length > 1 && selectedAccount &&
                    <div className={classes.mobileAccountDropdown}>
                        <div>
                            <AccountDropdown accounts={accounts} selectedAccount={selectedAccount} />
                        </div>
                    </div>
                }
            </div>
        );
    }

    return (
        <>
            <div className={classes.headerContainer}>
                <Card className={cardClasses.cardBackground}>
                    <div className={classes.header}>
                        {isDesktopOrLaptop ? <DefaultHeader /> : <MobileHeader />}
                    </div>
                </Card>
                {isLoading() &&
                    <ProgressBar className={classes.progressBar} shape='rounded' thickness='large' />
                }
            </div>
        </>
    );

}


export default Header;

