import { useEffect, useState, useContext } from "react";
import { AccountsDataContext } from "../app/contexts/AccountsDataContext";
import { EngageAppContext } from "../app/contexts/EngageAppContext";
import { LoggingContext } from "../app/contexts/LoggingContext";
import { useRest } from "./useRest";

export const useContactsData = () => {
    const logFilePrefix = 'useContactsData';
    const { accounts } = useContext(AccountsDataContext);
    const { setIsComponentLoading } = useContext(EngageAppContext);
    const { trackTraceVerbose, trackException } = useContext(LoggingContext);

    const [contact, setContact] = useState<any>();
    const { GET } = useRest();

    const hasEngageUserRole = (role: string) => {
      return contact?.userRoles?.includes(role);
    }

    const hasAnyEngageUserRole = (requiredRoles: any[]) => {

        if (!contact) {
            return false;
        }

        let result = false;
        requiredRoles.forEach(role => {
            if (contact.userRoles.some((x: any) => x == role)) {
                result = true;
                return;
            }
            
        });

        return result;
    };

    useEffect(() => {
        (async () => {
            const logName = `${logFilePrefix}-useEffect`;
            trackTraceVerbose(`${logName}`);

            setIsComponentLoading(logFilePrefix, true);

            try {
                let contactResponse;
                contactResponse = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Contacts`)
                const contactData = await contactResponse.json();
                setContact(contactData);

            }
            catch (error: any) {
                trackException(error)
            }
            finally {
                setIsComponentLoading(logFilePrefix, false);
            }

        })();

    }, []);

    return {
        contact,
        hasEngageUserRole,
        hasAnyEngageUserRole
    }
};

export default useContactsData;
