import { useContext, useEffect, useState } from 'react';

import { QuestionCircle24Regular, Open24Regular } from '@fluentui/react-icons';
import {
    makeStyles, Skeleton, SkeletonItem, Popover,
    PopoverTrigger, PopoverSurface, Button,
    tokens, Link, Caption1
} from '@fluentui/react-components';
import { AccountsDataContext } from '../../app/contexts/AccountsDataContext';
import { LoggingContext } from '../../app/contexts/LoggingContext';


const useStyles = makeStyles({
    questionIcon: {
        cursor: 'pointer',
    },
    feedbackButton: {
        marginTop: tokens.spacingVerticalS
    },
    version: {
        marginTop: tokens.spacingHorizontalM,
        textAlign: 'right',
        display: 'block'
    }
});

export default function SupportPopover() {
    const logFilePrefix = 'supportPopover';
    const { accounts, accountsLoading, accountError } = useContext(AccountsDataContext);
    const { trackPageView, trackEvent, trackTraceVerbose, getSessionId } = useContext(LoggingContext);

    const [sessionId, setSessionId] = useState<string>();

    const handlePopover = (e: any, data: any) => {
        if (data.open) {
            trackPageView('supportPopover');
        }
    }

    const selectedAccount = accounts.filter(a => a.selected)[0];

    const supportEmail = selectedAccount ? selectedAccount.supportEmail : process.env.REACT_APP_SUPPORT_EMAIL;
    const supportPhoneNumber = selectedAccount ? selectedAccount.supportPhoneNumber : process.env.REACT_APP_SUPPORT_PHONE;

    const version = process.env.REACT_APP_BUILD_NUMBER;
    const classes = useStyles();

    useEffect(() => {
        const sessionId = getSessionId();
        setSessionId(sessionId);
    }, []);

    const handleFeedbackClick = () => {
        trackEvent('leaveFeedbackClicked', {});
        window.open(`${process.env.REACT_APP_FEEDBACK_URL}`, '_blank');
    }

    const loadingSkeleton = () => {
        return (
            <Skeleton>
                <SkeletonItem />
                <br />
                <SkeletonItem />
            </Skeleton>
        );
    }

    const popover = () => {
        return (
            <>
                <Popover openOnHover={false} withArrow positioning="below-end" onOpenChange={handlePopover}>
                    <PopoverTrigger>
                            <QuestionCircle24Regular className={classes.questionIcon} />
                    </PopoverTrigger>
                    <PopoverSurface>
                        <div>
                            <div>
                                <div>Need Help?</div>
                                {
                                    accountsLoading === true && !accountError ? loadingSkeleton() :
                                        <div>
                                            <div>
                                                <Link href={`mailto:${supportEmail}?subject=Engage Support Help: ${sessionId}`}>
                                                    {supportEmail}
                                                </Link>
                                            </div>
                                            <div>
                                                <Link href={`tel:${supportPhoneNumber}`} target="_blank">{supportPhoneNumber}</Link>
                                            </div>
                                        </div>
                                        
                                }
                                <Button
                                    className={classes.feedbackButton}
                                    onClick={handleFeedbackClick}
                                    appearance='primary'
                                    icon={<Open24Regular />}
                                    iconPosition='after'
                                >
                                    <div>Leave Feedback</div>
                                </Button>
                                <Caption1 className={classes.version}>{version}</Caption1>
                            </div>
                        </div>
                    </PopoverSurface>
                </Popover>

            </>
        )
    }

    return (<>
        {
            popover()
        }
    </>);
}